import { fetch } from '../utils/index';
import Vue from 'vue';
let host = 'https://nftstorage.link'
// department or production
if (process.env.NODE_ENV === 'production') {
    host = 'https://nftstorage.link'
}
export const apis = {
    UserLogin: { // k线历史
        url: '/account/UserLogin'
    }
}

export const HOST = host

const WEB_API = {}

const listener = (value) => {
    let { url, method = 'get' } = value;
    host = value.host !== undefined ? value.host : host
    let query;
    let data;
    return (params, urlParam) => {
        let path = url
        if (params && params.formatUrl) {
            path = formatUrl(path, params.formatUrl)
            delete params.formatUrl
        }
        method === 'get' ? query = params : data = params;
        if (urlParam) {
            // url = `${url}?${urlParam}`
            query = urlParam
        }
        return fetch(host + path, query, data, method);
    }
}

function formatUrl(url, format) {
    format.forEach((item, index) => {
        url = url.replace(`{${index}}`, item)
    })
    return url
}

for(const key in apis) {
    WEB_API[key] = listener(apis[key]);
}
// console.log(WEB_API, 'web-api')
Vue.prototype.$api = WEB_API;

export default WEB_API;
