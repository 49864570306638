import Vue from 'vue'
import Vuex from 'vuex'
import { local } from '../config/constant'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        loading: false,
        account: '',
        chainId: '',
        currentProvider: null,
        collapse: true,
        breadcrumb: [],
        balance: 0,
        rewardAmount: 0,
        tools: [],
        symbol: '',
        rewardTokenAddress: '',
        endTime: 0,
        mncBalance: '0',
        lang: localStorage.getItem(local.LOCALE) || 'zh-CN',
        reload: 0,
        fromChain: null,
        toChain: null,
        fromBalance: 0,
        toBalance: 0,
        fromGasPrice: 0,
        toGasPrice: 0,
        minFromGasAmount: 0,
        minToGasAmount: 0,
        checkChainId: false,
        poolData: [],
        currentPool: {
            id: 0,
            type: 'current',
            tokenCode: '',
            // icon:'',
            icon: require('../assets/logo/BSC.png'),
            pair: '',
            rewardPerBlock: '0',
            startBlock: 0,
            endBlock: 0,
            price: 0
        },
        currentPoolPrice: 0,
        mncPrice: 0,
        // maxApr: 0,
        maxApr: 999,
        currentBlock: 0
    },
    mutations: {
        setLoading(state, value) {
            state.loading = value
        },
        setAccount(state, value) {
            state.account = value
        },
        setCollapse(state, value) {
            state.collapse = value
        },
        setBreadcrumb(state, value) {
            state.breadcrumb = value
        },
        setCurrentProvider(state, value) {
            state.currentProvider = value
        },
        setBalance(state, value) {
            state.balance = value
        },
        setRewardAmount(state, value) {
            state.rewardAmount = value
        },
        setTools(state, value) {
            // console.log(value, 'setTools')
            state.tools = value
        },
        setSymbol(state, value) {
            state.symbol = value
        },
        setChainId(state, value) {
            state.chainId = value
        },
        setRewardTokenAddress(state, value) {
            state.rewardTokenAddress = value
        },
        setEndTime(state, value) {
            state.endTime = value
        },
        setMncBalance(state, value) {
            state.mncBalance = value
        },
        setLang(state, value) {
            state.lang = value
        },
        setReload(state, value) {
            state.reload = value
        },
        setFromChain(state, value) {
            sessionStorage.setItem('FROM-CHAIN', value)
            state.fromChain = value
        },
        setToChain(state, value) {
            sessionStorage.setItem('TO-CHAIN', value)
            state.toChain = value
        },
        setFromBalance(state, value) {
            state.fromBalance = value
        },
        setToBalance(state, value) {
            state.toBalance = value
        },
        setFromGasPrice(state, value) {
            state.fromGasPrice = Number(value).toFixed(2) * 1
        },
        setToGasPrice(state, value) {
            state.toGasPrice = Number(value).toFixed(2) * 1
        },
        setMinFromGasAmount(state, value) {
            state.minFromGasAmount = value
        },
        setMinToGasAmount(state, value) {
            state.minToGasAmount = value
        },
        setCheckChainId(state, value) {
            state.checkChainId = value
        },
        setPoolData(state, value) {
            state.poolData = value
        },
        setCurrentPool(state, value) {
            state.currentPool = value
        },
        setMncPrice(state, value) {
            state.mncPrice = value
        },
        setMaxApr(state, value) {
            state.maxApr = value
        },
        setCurrentPoolPrice(state, value) {
            state.currentPoolPrice = value
        },
        setCurrentBlock(state, value) {
            state.currentBlock = value
        }
    },
    modules: {
    }
})

export default store
