import MinersNFTs from "../config/abi/MinersNFTs"
import {ADDRESS, ChainName, INITDATA, netWork} from "../config/constant"
import {HOST} from "../config/api"
import MncNFTStore from "../config/abi/MncNFTStore"
import {blockToDay} from "../utils"
import {mapState} from "vuex"
import BigNumber from "bignumber.js"

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

const defaultData = {
    id: '',
    name: '',
    locale: {},
    level: 0,
    image_url: '',
    staking: '',
    lockingTime: '',
    scarcity: '',
    total: '',
    myCount: '0',
    totalCount: '',
    stakeAmount: '0',
    stakeStatus: 0,
    stakingNow: false,
    endTime: '',
    expireBlock: '',
    publishBlock: '',
    rebateWaitBlock: '',
    rewardPerBlock: '0',
    token: '',
    nftTypeId: '',
    amountPerNFT: '',
    rebatePercentage: '',
    stakeCount: 0,
    backToCount: 0,
    blockNumber: 0,
    buyCount: 1,
    stakeloading: false,
    unstakeloading: false,
    rewardsloading: false,
    reward: '0',
    apr: 0,
    loading: false,
    disabled: false
}

export default {
    data() {
        return {
            tools: [],
            mncNFTStore: null,
            minersNFTs: null,
            timeOut: null
        }
    },
    computed: {
        ...mapState({
            account: (state) => state.account,
            balance: (state) => state.balance,
            reload: (state) => state.reload
        })
    },
    watch: {
        reload() {
            this.initTools()
        }
    },
    methods: {
        initTools() {
            this.tools = []
            this.$store.commit('setTools', [])
            const chainName = ChainName[this.$store.state.chainId]
            // console.log(chainName, 'chainName', this.$store.state.account)
            this.minersNFTs = this.$vueweb3.contract(MinersNFTs, ADDRESS[chainName].minersnfts).methods
            this.mncNFTStore = this.$vueweb3.contract(MncNFTStore, ADDRESS[chainName].mncnftstore).methods
            // clearTimeout(this.timeOut)
            // return this.$ajax('init', {url: IPFSURL[this.$i18n.locale]})
            return Promise.resolve(INITDATA).then(res => {
                const array = []
                for(let key in res) {
                    key = key * 1
                    array.push(this.getData(key, res[key]))
                }
                return this.reloadData(array)
            })
        },
        reloadData(array) {
            return Promise.all(array).then((data) => {
                // const toolsData = []
                const dataArray = []
                data.forEach(item => {
                    const tool = Object.assign(JSON.parse(JSON.stringify(defaultData)), item)
                    // toolsData.push(tool)
                    dataArray.push(this.getProductInfos(this.mncNFTStore, tool), this.getQuqntity(this.minersNFTs, tool),this.poolInfos(tool))

                })
                // this.tools = toolsData
                return Promise.all(dataArray).then(res => {
                    // console.log(res, 'this.$store.commit(\'setTools\', tools)')
                    const tools = []
                    res.forEach((item, index) => {
                        if (index === 0 || index === 3 || index === 6) {
                            tools.push(item)
                        }
                    })
                    this.$store.commit('setTools', tools)
                    this.tools = tools
                    this.$store.commit('setLoading', false)
                    return res
                })
            }).catch(err => {
                console.log(err, 44444444444)
            })
        },
        loopData() {
            const promiseAll = []
            const timeout = 1000 * 60
            clearTimeout(this.timeOut)
            if (this.tools.length === 0) {
                this.timeOut = setTimeout(() => {
                    this.loopData()
                }, timeout)
                return
            }
            this.tools.forEach(item => {
                const tool = {
                    id: item.id,
                    image_url: item.image_url,
                    level: item.level,
                    locale: item.locale,
                    cn: item.cn,
                    en: item.en,
                    name: item.name,
                    host: item.host
                }
                promiseAll.push(Promise.resolve(tool))
            })
            this.reloadData(promiseAll).then(() => {
                this.timeOut = setTimeout(() => {
                    this.loopData()
                }, timeout)
            })
        },
        poolInfos(item) {
            const stakingpools = this.$vueweb3.contract(this.$abi.StakingPools,  ADDRESS[ChainName[this.$store.state.chainId]].stakingpools(this.$store.state.currentPool.id)).methods
            return stakingpools.poolInfos(item.id).call().then(res => {
                // const endBlock = new BigNumber(res.endBlock)
                // const apr = endBlock.minus(res.startBlock).times(res.rewardPerBlock).div(30).times(365).toString()
                // item.apr = apr
                // console.log(res.rewardPerBlock, 'res.rewardPerBlock')
                item.rewardPerBlock = res.rewardPerBlock || 0
                item.startBlock = res.startBlock
                item.endBlock = res.endBlock
                return this.$ajax('getBlockNumber', {
                    url: netWork[ChainName[this.$store.state.chainId]].rpcUrls[0],
                    method: 'post',
                    data: {
                        id: new Date().getTime(),
                        method: 'eth_blockNumber',
                        jsonrpc: '2.0',
                        params: []
                    }
                }).then(result => {
                    const num = this.$web3.utils.hexToNumberString(result.result)
                    // console.log(num, 222222, res.startBlock)
                    item.stakingNow = (res.startBlock * 1 < num * 1)
                    return item
                })
            })
            // const currentPool = this.$store.state.currentPool
            // item.rewardPerBlock = currentPool.rewardPerBlock
            // item.startBlock = currentPool.startBlock
            // item.endBlock = currentPool.endBlock
            // item.stakingNow = (currentPool.startBlock < this.$store.state.currentBlock)
            // return Promise.resolve(item)
        },
        getProductInfos(methods, item) {
            // console.log(id, 111111)
            return methods.productInfos(item.id).call({form: this.$store.state.account}).then (result => {
                // console.log(result, 'result.amountPerNFT', id)
                const staking = this.$web3.utils.fromWei(result.amountPerNFT + '', 'ether')
                const lockingTime = blockToDay(result.expireBlock + '', this.$store.state.currentBlock + '')
                item.staking = staking
                item.lockingTime = lockingTime
                item.amountPerNFT = result.amountPerNFT
                item.expireBlock = result.expireBlock
                item.nftTypeId = result.nftTypeId
                item.publishBlock = result.publishBlock
                item.rebatePercentage = result.rebatePercentage
                item.rebateWaitBlock = result.rebateWaitBlock
                item.token = result.token
                item.disabledBuy = (result.publishBlock > this.$store.state.currentBlock)
                item.blockNumber = this.$store.state.currentBlock
                return item
            })
        },
        getQuqntity(methods, item) {
            // console.log(this.$store.state.account, 'this.$store.state.account', methods)
            return methods.nftMaxQuantity(item.id).call({form: this.$store.state.account}).then(result => {
                // console.log(result, 'nftMaxQuantity')
                result = this.$bigNumber(result)
                return methods.nftQuantity(item.id).call({form: this.$store.state.account}, (err, res) => {
                    const amount = result.minus(this.$bigNumber(res)).toString()
                    // console.log(amount, 'amount')
                    const quantity = this.$web3.utils.fromWei(amount, 'ether')
                    return methods.governance().call().then(address => {
                        if (address !== ADDRESS[ChainName[this.$store.state.chainId]].mncnftstore) {
                            item.disabled = true
                        }
                        item.totalCount = this.$web3.utils.fromWei(result.toString(), 'ether')
                        item.scarcity = quantity
                        return item
                    })
                })
            }).catch(err => {
                console.log(err, 'rrrr')
            })
        },
        getData(index, url) {
            console.log(HOST + url)
            return this.$ajax((new Date().getTime()) + '', {
                url: HOST + url
            }).then(res => {
                res.host = HOST
                res.level = (res.level || 0) * 1
                return res
            })
        },
        checkingApproveMnc(approveMethods, amount, address) {
            return approveMethods.approve(address, amount).send({from: this.$store.state.account})
        },
        checkingApproveNFt(approveMethods, amount, address) {
            return approveMethods.isApprovedForAll(this.$store.state.account, address).call({})
        },
        getTransactionReceipt(hash, fn) {
            setTimeout(() => {
                this.$web3.eth.getTransactionReceipt(hash).then((result) => {
                    console.log(result, hash)
                    if (result && result.status) {
                        fn()
                    } else {
                        this.getTransactionReceipt(hash, fn)
                    }
                })
            }, 5 * 3 * 1000)
        },
        formatValue(val, decimal) {
            const value = this.$bigNumber(val)
            return  value.toFixed(decimal)
        },
        convert(n) {
            n += ''
            const x = n.split('.')
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : ''
            const rgx = /(\d+)(\d{3})/
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2')
            }
            return x1 + x2
        },
        setApr(item) {
            if (this.$store.state.chainId === '0x80' && this.$store.state.currentPool.tokenCode === 'MNC') {
                if (item.id === '1') {
                    return 95
                }
                if (item.id === '2') {
                    return 138
                }
                if (item.id === '3') {
                    this.$store.commit('setMaxApr', 189)
                    return 189
                }
            }
            const mncAmount = this.$bigNumber(item.staking)
            const mncTotal = mncAmount.times(this.$store.state.mncPrice)
            const rewardPerBlock = this.$bigNumber(this.$web3.utils.fromWei((item.rewardPerBlock || 0) + '', 'ether'))
            const rewardTokenTotal = rewardPerBlock.times(10512000).times(this.$store.state.currentPoolPrice)
            let apr = rewardTokenTotal.div(mncTotal).times(100).decimalPlaces(0).toString()

            // FIXME: placeholder if wallet not connected
            if (apr == "NaN") {
                apr = 999;
            }

            if (item.id === this.$store.state.tools[this.$store.state.tools.length - 1].id) {
                this.$store.commit('setMaxApr', apr)
            }
            return apr

        },
        setName(item) {
            if (!item.locale) {
                item.locale = {
                    cn: '',
                    en: ''
                }
            }
            if (this.$i18n.locale === 'zh-CN') {
                return item.locale.cn
            } else {
                return item.locale.en
            }
        },
        decimalValue(val, decimal) {
            const value = this.$bigNumber(val)
            return  value.toFixed(decimal) * 1
        }
    }
}
